<template>
  <div class="speech row m-0">
    <div class="col-12 col-lg-8">
      <div class="h-100">
        <h5 class="intro-title text-xl" v-if="aiType === 'speech'">日文演講稿</h5>
        <h5 class="intro-title text-xl" v-if="aiType === 'letter'">商用日文書信</h5>
        <form v-if="step === 0 && aiType === 'speech'" >
          <p class="intro-title text-lg"><img src="@/assets/image/ai/quote.png" alt="">演講資訊</p>
          <!-- <div class="form-group row mx-0">
            <label for="type" class="col-form-label mr-2 text-lg font-semibold form-label">類型</label>
            <div class="flex-1 border" :class="{ 'inValidError': isShowErr && !type }">
              <input type="text" class="form-control" id="type" placeholder="例： 會議主持" v-model="type">
              <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !type">*此欄必須填寫</span>
            </div>
          </div> -->
          <div class="form-group row mx-0">
            <label for="topic" class="col-form-label mr-2 text-lg font-semibold form-label">主題</label>
            <div class="flex-1" :class="{ 'inValidError': isShowErr && !topic }">
              <input type="text" class="form-control" id="topic" placeholder="例： 外賓來訪主持會議開場" v-model="topic">
              <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !topic">*此欄必須填寫</span>
            </div>
          </div>
          <div class="form-group row mx-0">
            <label for="people" class="col-form-label mr-2 text-lg font-semibold form-label">對象</label>
            <div class="flex-1" :class="{ 'inValidError': isShowErr && !audience }">
              <input type="text" class="form-control" id="people" placeholder="例： 客戶與公司一級主管" v-model="audience">
              <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !audience">*此欄必須填寫</span>
            </div>
          </div>
          <div class="form-group" :class="{ 'inValidError': isShowErr && !content }">
            <label for="detail" class="text-lg font-semibold form-label">內容簡述 (字數上限100字)</label>
            <textarea v-model="content" class="form-control" id="detail" rows="8"
            placeholder="例：
1. 向遠道而來的外賓表達歡迎詞
2. 進行簡單自我介紹
3. 針對會議目的和今天的議程，進行概要說明
4. 誠摯表達與對方合作的期待

提醒：建議以列點的方式簡述您欲演講的段落架構，讓小幫手協助您產出更符合需求的內容"></textarea>
            <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !content">*此欄必須填寫</span>
          </div>
          <div class="w-100 d-flex justify-content-center">
            <!-- <a class="btn-clear mx-2 d-inline-block" @click="reset">清空</a> -->
            <a class="btn-submit mx-2 d-inline-block py-2" @click="createLetter()">START</a>
          </div>
        </form>
        <form v-if="step === 0 && aiType === 'letter'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <p class="intro-title text-lg"><img src="@/assets/image/ai/quote.png" alt="">寄件人資訊</p>
              <div class="form-group row mx-0">
                <label for="SenderName" class="col-form-label mr-2 text-lg font-semibold form-label">寄件人姓名</label>
                <div class="flex-1" :class="{ 'inValidError': isShowErr && !s_name }">
                  <input type="text" class="form-control" id="SenderName" placeholder="例： 田中太郎" v-model="s_name">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !s_name">*此欄必須填寫</span>
                </div>
              </div>
              <div class="form-group row mx-0">
                <label for="SenderAppellation" class="col-form-label mr-2 text-lg font-semibold form-label">寄件人稱謂</label>
                <div class="flex-1" :class="{ 'inValidError': isShowErr && !s_itentity }">
                  <input type="text" class="form-control" id="SenderAppellation" placeholder="例：業務專員" v-model="s_itentity">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !s_itentity">*此欄必須填寫</span>
                </div>
              </div>
              <div class="form-group row mx-0">
                <label for="SenderDepartment" class="col-form-label mr-2 text-lg font-semibold form-label">寄件人公司</label>
                <div class="flex-1" :class="{ 'inValidError': isShowErr && !s_company }">
                  <input type="text" class="form-control" id="SenderDepartment" placeholder="例： ABC公司" v-model="s_company">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !s_itentity">*此欄必須填寫</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <p class="intro-title text-lg"><img src="@/assets/image/ai/quote.png" alt="">收件人資訊</p>
              <div class="form-group row mx-0">
                <label for="addresseeName" class="col-form-label mr-2 text-lg font-semibold form-label">收件人姓名</label>
                <div class="flex-1" :class="{ 'inValidError': isShowErr && !r_name }">
                  <input type="text" class="form-control" id="addresseeName" placeholder="例： 山本花子" v-model="r_name">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !r_name">*此欄必須填寫</span>
                </div>
              </div>
              <div class="form-group row mx-0">
                <label for="addresseeAppellation" class="col-form-label mr-2 text-lg font-semibold form-label">收件人稱謂</label>
                <div class="flex-1" :class="{ 'inValidError': isShowErr && !r_itentity }">
                  <input type="text" class="form-control" id="addresseeAppellation" placeholder="例：業務經理" v-model="r_itentity">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !r_itentity">*此欄必須填寫</span>
                </div>
              </div>
              <div class="form-group row mx-0">
                <label for="addresseeDepartment" class="col-form-label mr-2 text-lg font-semibold form-label">收件人公司</label>
                <div class="flex-1" :class="{ 'inValidError': isShowErr && !r_company }">
                  <input type="text" class="form-control" id="addresseeDepartment" placeholder="例： XYZ公司" v-model="r_company">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !r_company">*此欄必須填寫</span>
                </div>
              </div>
            </div>

            <div class="col-12">
              <p class="intro-title text-lg"><img src="@/assets/image/ai/quote.png" alt="">信件資訊</p>
                <div class="form-group" :class="{ 'inValidError': isShowErr && !purpose }">
                  <label for="emailTitle" class="text-lg font-semibold form-label">信件主旨</label>
                  <input type="text" class="form-control" id="emailTitle" placeholder="例： 專案合作細節洽談" v-model="purpose">
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !purpose">*此欄必須填寫</span>
                </div>
                <div class="form-group" :class="{ 'inValidError': isShowErr && !content }">
                  <label for="emailTitle" class="text-lg font-semibold form-label">內容簡述 (字數上限100字)</label>
                  <textarea class="form-control" id="emailTitle" rows="8" placeholder="例： 關於本次新產品專案合作細節，針對上市時程、合作細節、分潤模式，希望能約貴公司下週進行當面會談，以利雙方業務推展。" v-model="content"></textarea>
                  <span class="text-sm text-red-main-ai m-0" v-if="isShowErr && !content">*此欄必須填寫</span>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <!-- <a class="btn-clear mx-2 d-inline-block" @click="reset">清空</a> -->
              <a class="btn-submit mx-2 d-inline-block py-2" @click="createLetter">START</a>
            </div>
          </div>
        </form>
        <GenerateResults v-if="step === 1" :resultsEn="resultsEn" :resultsZh="resultsZh" :ttsUrlMale="ttsUrlMale" :ttsUrlFemale="ttsUrlFemale" v-on:resultsZh="emitResultsZh" v-on:failPopUp="failPopUp" v-on:getZnPopUp="getZnPopUp" :aiType="aiType" />
      </div>
    </div>
    <div class="col-12 col-lg-4 mt-3 mt-lg-0">
      <Rule v-if="step === 0" :aiType="aiType" />
      <InputData v-if="step === 1 || step === 2" v-on:editData="editData" :aiType="aiType" :topic="topic" :content="content" :audience="audience" :r_name="r_name" :r_itentity="r_itentity" :r_company="r_company" :s_name="s_name" :s_itentity="s_itentity" :s_company="s_company" :purpose="purpose" />
    </div>
  </div>
</template>

<script>
import api from '@/store/api'
import Rule from '@/components/AiHelper/rule.vue'
import InputData from '@/components/AiHelper/speech/inputData.vue'
import GenerateResults from '@/components/AiHelper/speech/generateResults.vue'
import allJs from '@/components/AiHelper/all.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Speech',
  components: {
    Rule,
    InputData,
    GenerateResults
  },
  computed: {
    ...mapGetters([
      'remainPoints'
    ]),
    aiType () {
      return this.$route.params.aiType
    }
  },

  data () {
    return {
      step: 0,
      isShowErr: false,

      content: null,

      // type: null,
      topic: null,
      audience: null,

      r_name: null,
      r_itentity: null,
      r_company: null,
      s_name: null,
      s_itentity: null,
      s_company: null,
      purpose: null,

      // type: '人際關係',
      // topic: '設立界線對於建立健康人際關係的益處',
      // content: '暢銷大眾心理學者針對設立界線主題寫書，並且在書店扮演講',
      // audience: '讀者',

      // r_name: 'Leo',
      // r_itentity: '業務經理',
      // r_company: 'LiveABC',
      // s_name: 'Rex',
      // s_itentity: '打雜人員',
      // s_company: 'LiveABC',
      // purpose: '談合作策略',
      // content: '如何善用兩公司優勢創照1+1大於2的力量',

      resultsEn: null,
      resultsZh: null,
      ttsUrlMale: null,
      ttsUrlFemale: null
    }
  },
  mixins: [
    allJs
  ],
  mounted () {
  },
  methods: {
    emitResultsZh (emitResultsZh) {
      let self = this
      self.resultsZh = emitResultsZh
    },

    editData () {
      let self = this
      self.step = 0
      self.resultsZh = null
    },

    createLetter () {
      let self = this
      if ((self.aiType === 'speech' && self.topic && self.content && self.audience) || (self.aiType === 'letter' && self.r_name && self.r_itentity && self.s_name && self.s_itentity && self.purpose && self.content && self.r_company && self.s_company)) {
        if (self.remainPoints < 20) {
          self.failPopUp('點數不足')
        } else {
          self.swal_startCreate()
        }
      } else {
        self.isShowErr = true
      }
    },

    swal_startCreate () {
      let self = this
      let typeText = '商用日文書信'
      let calPoints = self.remainPoints - 20
      self.aiType === 'speech' ? typeText = '日文演講稿' : null
      self.$swal({
        html: `<h3>確定開始生成${typeText}？</h3><p>目前總點數 <span style="color: #F30000;">${self.remainPoints}</span> 點</p><p>需扣除 20 點</p><p>扣除後點數 <span style="color: #F30000;">${calPoints}</span> 點</p>`,
        showDenyButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        denyButtonText: `返回修改`,
        title: 'Notice',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'htmlContainer my-3',
          actions: 'mb-4 mt-5 d-flex',
          confirmButton: 'btn-submit order-2',
          denyButton: 'denyButton-ai order_1',
        }
      })
        .then((result) => {
          if (result.isConfirmed === true) {
            self.postData()
          }
        }
      )
    },

    postData () {
      let self = this
      let jsonData
      if (self.aiType === 'letter') {
        jsonData = {
          r_name: self.r_name,
          r_itentity: self.r_itentity,
          r_company: self.r_company,

          s_name: self.s_name,
          s_itentity: self.s_itentity,
          s_company: self.s_company,

          letter_purpose: self.purpose,
          letter_content: self.content
        }
      } else {
        jsonData = {
          // type: self.type,
          s_topic: self.topic,
          speech_content: self.content,
          audience: self.audience
        }
      }

      let path = `/ai/generate/${self.aiType}`

        api.post(path, JSON.stringify(jsonData))
        .then(async(res) => {
          if (res.data && res.data.result) {
            self.resultsEn = res.data.result

            self.step = 1

          // eslint-disable-next-line
            self.ttsUrlFemale = await self.getTTSUrl('female', res.data.result)
            self.ttsUrlMale = await self.getTTSUrl('male', res.data.result)

            self.$store.dispatch('getRemainPoints')
          } else {
            self.failPopUp()
          }
        })
        .catch(() => {
          self.failPopUp('請確認輸入的內容')
        })
    },

    failPopUp (text) {
      let self = this
      let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
      self.$swal.fire({
        html: defaultTitle,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        customClass: {
          container: '',
          popup: 'p-0',
          header: 'swal-header',
          closeButton: '',
          htmlContainer: 'htmlContainer my-3',
          actions: 'mb-4 mt-5',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    },

    getZnPopUp () {
      let self = this
      self.$swal.fire({
        html: `<h3>翻譯完成</h3>`,
        showCloseButton: false,
        focusConfirm: false,
        confirmButtonText: `確認`,
        customClass: {
          container: '',
          popup: 'p-0',
          header: 'swal-header-success',
          closeButton: '',
          htmlContainer: 'htmlContainer my-3',
          actions: 'mb-4 mt-5',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .speech {
    @media (min-width: 640px) {
      padding: 1.5rem !important;
    }
    @media (min-width: 640px) {
      padding: 1.5rem !important;
    }
  }
  form {
    label {
      white-space: nowrap;
    }
    .form-label {
      @media (max-width: 1024px) {
        font-size: 1rem;
      }
    }
  }
  .intro-title {
    color: #F30000;
    font-weight: 700;
    margin-bottom: 0.75rem;
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
</style>
