<template>
  <div>
    <div class="intro-banner text-white text-xl">
      <p v-if="aiType === 'letter'">信件資訊</p>
      <p v-if="aiType === 'speech'">「日文演講稿資訊」</p>
    </div>

    <div class="intro-text bg-white p-4 text-lg">
      <h6 @click="swal_remainPoints" class="mb-1 font-bold text-lg">目前總點數：<span>{{ Number(remainPoints) }}</span> 點
        <a @click="swal_remainPoints" class="pointer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg></a>
      </h6>
      <form class="font-semibold" v-if="aiType === 'letter'">
        <div class="form-group">
          <label for="disabledTextInput">寄件人姓名</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="s_name">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">寄件人稱謂</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="s_itentity">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">寄件人公司</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="s_company">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">收件人姓名</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="r_name">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">收件人稱謂</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="r_itentity">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">收件人公司</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="r_company">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">信件主旨</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="purpose">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">信件內容描述</label>
          <textarea v-model="content" class="form-control" id="emailTitle" rows="3" placeholder="EX: 要誠摯邀請對方參與即將舉行的商業會議，共同探討機會，共創未來，會議時間在下個月13號。" disabled></textarea>
        </div>
        <a class="btn-submit mx-2 d-inline-block pointer" @click="editData">修改信件資訊</a>
      </form>
      <form class="font-semibold" v-if="aiType === 'speech'">
        <!-- <div class="form-group">
          <label for="disabledTextInput">演講類型</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="type">
        </div> -->
        <div class="form-group">
          <label for="disabledTextInput">演講主題</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="topic">
        </div>
        <!-- <div class="form-group">
          <label for="disabledTextInput">演講時長</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="duration">
        </div> -->
        <!-- <div class="form-group">
          <label for="disabledTextInput">演講場合</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="occasion">
        </div> -->
        <div class="form-group">
          <label for="disabledTextInput">演講對象</label>
          <input type="text" id="disabledTextInput" class="form-control" placeholder="" disabled v-model="audience">
        </div>
        <div class="form-group">
          <label for="disabledTextInput">演講細節</label>
          <textarea v-model="content" class="form-control" id="emailTitle" rows="3" placeholder="EX: 要誠摯邀請對方參與即將舉行的商業會議，共同探討機會，共創未來，會議時間在下個月13號。" disabled></textarea>
        </div>
        <a class="btn-submit mx-2 d-inline-block pointer" @click="editData">
          <span v-if="aiType === 'letter'">修改信件資訊</span>
          <span v-if="aiType === 'speech'">修改日文演講稿資訊</span></a>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'inputData',
  props: {
    aiType: {
      type: String,
      default: null
    },
    r_name: {
      type: String,
      default: null
    },
    r_itentity: {
      type: String,
      default: null
    },
    r_company: {
      type: String,
      default: null
    },
    s_name: {
      type: String,
      default: null
    },
    s_itentity: {
      type: String,
      default: null
    },
    s_company: {
      type: String,
      default: null
    },
    purpose: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: null
    },
    topic: {
      type: String,
      default: null
    },
    // duration: {
    //   type: String,
    //   default: null
    // },
    // occasion: {
    //   type: String,
    //   default: null
    // },
    audience: {
      type: String,
      default: null
    },

    content: {
      type: String,
      default: null
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters([
      'remainPoints'
    ])
  },
  methods: {
    editData () {
      this.$emit('editData')
    },
    swal_remainPoints () {
      let self = this
      self.$swal({
        html: `<h6>目前總點數：<span class="text-red-main-ai">${self.remainPoints}</span> 點</h6><p class="text-left">本區依企業方案提供初始點數供您體驗 AI 服務，每次使用以下服務將自動扣除點數：</p><ul><li>商用日文書信：每次 20 點</li><li>日文演講稿：每次 20 點</li><li>口說練習：每句 1 點</li></ul>`,
        showCloseButton: false,
        focusConfirm: false,
        confirmButtonText: `我知道了`,
        title: '使用規範',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'remainPoints_swal my-3',
          actions: 'mb-4 mt-2',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          self.postData()
        }
      }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
  .intro-banner {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #0E103E;
    text-align: center;
    font-weight: 700;
    background-image:
      url('~@/assets/image/ai/plusSS.png'), url('~@/assets/image/ai/plusSS.png'),
      url('~@/assets/image/ai/plusS.png'), url('~@/assets/image/ai/plusS.png'),
      url('~@/assets/image/ai/plusB.png'), url('~@/assets/image/ai/plusB.png'),
      url('~@/assets/image/ai/robotL.png'), url('~@/assets/image/ai/robotR.png');
    background-repeat: no-repeat;
    background-position:
      left 18% top 15%, right 18% top 15%,
      left 6% top 30%, right 6% top 30%,
      left 13% top 55%, right 13% top 55%,
      left 10% bottom 0%, right 5% bottom 0%;
    p {
      margin: 0;
    }
    @media (max-width: 1024px) {
      font-size: 1.125rem;
    }
  }
  .intro-text{
    svg {
      width: 1em;
    }
    h6 {
      span {
        color: #F30000;
      }
      @media (max-width: 1024px) {
        font-size: 1rem;
      }
    }
    p {
      margin-bottom: 1.25rem;
      line-height: 150%;
      font-weight: 500;
      @media (max-width: 1024px) {
        font-size: 1rem;
      }
    }
    .title {
      font-weight: 700;
    }
    .icon-img {
      max-width: 1.5em;
      height: auto;
      object-fit: contain;
    }
  }
</style>
